import React from 'react'
import './Footer.css'
import{BsLinkedin} from 'react-icons/bs'
import{FaGithub} from 'react-icons/fa'
import { useTranslation } from "react-i18next";

const Footer = () => {
  const{t}= useTranslation()
  return (
    <footer>
      <ul className='permalinks'>
        <li><a href="#">{t('home')}</a></li>
        <li><a href="#about">{t('About')}</a></li>
        <li><a href="#experience">{t('Skills')}</a></li>
        <li><a href="#portfolio">{t('Projects')}</a></li>
      </ul>

      <div className='footer__socials'>
      <a href="https://www.linkedin.com/in/math%C3%A9o-joseph-a85a27194/" target="_blank"><BsLinkedin/></a>
        <a href="https://github.com/MatheoJ" target="_blank"><FaGithub/></a>
    </div>
    </footer>
  )
}

export default Footer