import React from 'react'
import './About.css'
import ME from '../../assets/IMG_20230713_175226.jpg'
import {FaGraduationCap} from 'react-icons/fa'
import {GiMountainClimbing} from 'react-icons/gi'
import {GiTennisRacket} from 'react-icons/gi'
import {GiVintageRobot} from 'react-icons/gi'
import {GiEcology} from 'react-icons/gi'
import { useTranslation } from "react-i18next";

const About = () => {
  const{t}= useTranslation()
  return (
    <section id='about'>
      <h5> {t('About2')}</h5>
      <h2> {t('Me')}</h2>
      <div className='container about__container'>
        <div className='about__me'>
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        <div className='about__content'>
          <div className='about__cards'>
            <articlec className='about__card'>
              <FaGraduationCap className='about__icon'/>
              <h5>{t('Studies')}</h5>
              <small>{t('Studies_Text')}</small>
            </articlec>

            <articlec className='about__card'>
              <GiMountainClimbing className='about__icon'/>
              <GiTennisRacket className='about__icon'/>
              <h5>Sport</h5>
              <small>{t('Sport_Text')}</small>
            </articlec>

            <articlec className='about__card'>
              <GiEcology className='about__icon'/>
              <h5>Tech4Good</h5>
              <small>{t('T4G_Text')}</small>
            </articlec>
          </div>
          <p>
            {t('Presentation1')}
            <br/>
            <br/>
            {t('Presentation2')}
            <br/>
            <br/>
            {t('Presentation3')}
            <br/>
            <br/>
            {t('Presentation4')}
          </p>
          <a href='#contact' className='btn btn-primary'> Contact</a>
        </div>
      </div>
    </section>
  )
}

export default About