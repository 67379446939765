import React from 'react'
import CV from '../../assets/CV_Matheo_Joseph_2024.pdf'
import CV2 from '../../assets/Resume_Matheo_Joseph_2024.pdf'
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie'

const CTA = () => {
  const{t}= useTranslation()
  const currentLanguage = cookies.get('i18next')||'en'
  if(currentLanguage=='en'){
    return (
      <div className='cta'>
          <a href={CV2} download className='btn'>{t('resume_dl')}</a>
          <a href="#portfolio" className='btn btn-primary'>{t('My Projects')}</a>
      </div>
    )
  }
  else{
    return (
      <div className='cta'>
          <a href={CV} download className='btn'>{t('resume_dl')}</a>
          <a href="#portfolio" className='btn btn-primary'>{t('My Projects')}</a>
      </div>
    )
  }  
}

export default CTA