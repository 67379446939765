import React from 'react'
import './Contact.css'
import {AiOutlineMail} from  'react-icons/ai'
import {AiOutlineWhatsApp} from 'react-icons/ai'
import{BsLinkedin} from 'react-icons/bs'
import { useTranslation } from "react-i18next";

const Contact = () => {
  const{t}= useTranslation()
  return (
    <section id='contact'>
      <h2>{t('Contact me')}</h2>
      <div className="container contact__container">
            <article className='contact__option'>
              <AiOutlineMail className='contact__option-icon'/>
              <h4>Email</h4>
              <h5>matheo.joseph.01@gmail.com</h5>
              <a href="mailto:matheo.joseph.01@gmail.com" target="_blank">{t('SendMail')}</a>
            </article>
            <article className='contact__option'>
              <BsLinkedin className='contact__option-icon'/>
              <h4>Linkedin</h4>
              <h5>Mathéo Joseph</h5>
              <a href="https://www.linkedin.com/in/math%C3%A9o-joseph-a85a27194/" target="_blank">{t('GoToProfil')}</a>
            </article>
      </div>      
    </section>
  )
}

export default Contact