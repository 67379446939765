import React from 'react'
import './Header.css'
import CTA from './CTA'
import HeaderSocials from './HeaderSocials'
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import CTA_Lang from './CTA_Lang';

const Header = () => {
  const{t}= useTranslation()
  return (
    <header>
      <div className="container header__container">
        <div className='header__links'>
          <ul className='permalinks'>
            <li><a href="#">{t('home')}</a></li>
            <li><a href="#about">{t('About')}</a></li>
            <li><a href="#experience">{t('Skills')}</a></li>
            <li><a href="#portfolio">{t('Projects')}</a></li>            
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>
        <CTA_Lang/>       
        <div className='header__basics'> 
          <h1>
            Mathéo JOSEPH
          </h1>  
          <h5 className='text-light'>
            {t('welcome')}
          </h5>  
          
          <h5>
            <br/>
            {t('looking_for')}
          </h5> 
          <CTA/>          
        </div>
         
        <HeaderSocials/>
        {/* <div className='me'>
          <img src={ME} alt="me" />
        </div> */}
        <a href="#contact" className='scroll__down'>
            Scroll Down
        </a>
      </div>
    </header>
  )
}

export default Header