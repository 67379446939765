import React from 'react'
import './Portfolio.css'
import IMG1 from '../../assets/Airwatcher.jpg'
import IMG2 from '../../assets/Analog2.PNG'
import IMG3 from '../../assets/Reactif.png'
import IMG4 from '../../assets/Predictif2.jpg'
import IMG5 from '../../assets/Pandemic.png'
import IMG6 from '../../assets/tracker.png'
import IMG7 from '../../assets/Minautorus.PNG'
import IMG8 from '../../assets/p2i.PNG'
import IMG9 from '../../assets/OSP.PNG'
import IMG10 from '../../assets/Labeliser.PNG'
import IMG11 from '../../assets/stage.jpg'
import IMG12 from '../../assets/demo.PNG'
import IMG15 from '../../assets/Infographie.png'
import IMG14 from '../../assets/PhysicsEngine.JPG'
import IMG17 from '../../assets/AI_JeuxVideo.JPG'
import IMG16 from '../../assets/C_compiler.jpg'
import IMG13 from '../../assets/Plateformer2.JPG'
import IMG19 from '../../assets/Deliverif.PNG'
import IMG18 from '../../assets/Perkastor.PNG'
import IMG20 from '../../assets/Scientif.PNG'
import IMG21 from '../../assets/RayTracing.JPG'


import { useTranslation } from "react-i18next";
import i18next from 'i18next';



const Portfolio = () => {

  const{t}= useTranslation();

  const data = [
    {
      id: 15,
      image: IMG15,
      title: i18next.t('3DWorld'),
      stack: 'C++, OpenGL',
      github: 'https://github.com/MatheoJ/OpenglWorld3D',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail:i18next.t('3DWorld_Text')
    },
    {
      id: 14,
      image: IMG14,
      title: i18next.t('PhysicsEngine'),
      stack: 'C++, SDL2',
      github: 'https://github.com/MatheoJ/PhysicEngine2D',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail:i18next.t('PhysicsEngine_Text')
    },{
      id: 13,
      image: IMG13,
      title: i18next.t('Platformer'),
      stack: 'Unity, C#',
      github: 'https://github.com/MatheoJ/GustaveTheGhost',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail:i18next.t('Platformer_Text')
    },
    {
      id: 21,
      image: IMG21,
      title: i18next.t('RayTracing'),
      stack: 'C++, ImGui',
      github: 'https://github.com/MatheoJ/Raytracing',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail:i18next.t('RayTracing_Text')
    },
    {
      id: 17,
      image: IMG17,
      title: i18next.t('AIVideoGame'),
      stack: 'Unreal Engine 5, C++',
      github: '',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail:i18next.t('AIVideoGame_Text')
    },{
      id: 18,
      image: IMG18,
      title: i18next.t('Perkastor'),
      stack: 'NextJS, TypeScript, MongoDB',
      github: 'https://github.com/MatheoJ/perkastor',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail:i18next.t('Perkastor_Text')
    },{
      id: 19,
      image: IMG19,
      title: i18next.t('Deliverif'),
      stack: 'Java',
      github: 'https://github.com/MatheoJ/Deliverif',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail:i18next.t('Deliverif_Text')
    },{
      id: 20,
      image: IMG20,
      title: i18next.t('Scientif'),
      stack: 'NodeJS, JavaScript',
      github: 'https://github.com/MatheoJ/ScientIF',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail:i18next.t('Scientif_Text')
    },
    {
      id: 16,
      image: IMG16,
      title: i18next.t('compiler'),
      stack: 'C++, ANTRL4',
      github: 'https://github.com/MatheoJ/Compiler_C_language',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail:i18next.t('compiler_Text')
    },
    {
      id: 11,
      image: IMG11,
      title: i18next.t('Internship'),
      stack: 'C++: GUI with SFML',
      github: 'https://github.com/MatheoJ/kInARowStrategyFinder',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail:i18next.t('Internship_Text')
    },
    {
      id: 9,
      image: IMG9,
      title: i18next.t('OSP'),
      stack: 'Docker, Python: ML with sklearn, Api with flask',
      github: '',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail:i18next.t('OSP_Text')
    }
    ,
    {
      id: 1,
      image: IMG1,
      title: i18next.t('AirWatcher'),
      stack: 'C++',
      github: 'https://github.com/MatheoJ/AirWatcher',
      demo: 'https://github.com/MatheoJ/AirWatcher',
      detail:i18next.t('AirWatcher_Text')
    },
    {
      id: 2,
      image: IMG2,
      title: i18next.t('Analog'),
      stack: 'C++',
      github: 'https://github.com/MatheoJ/Analog',
      demo: 'https://github.com/MatheoJ/AirWatcher',
      detail:i18next.t('Analog_Text')
    },
    {
      id: 3,
      image: IMG3,
      title: i18next.t('Reactif'),
      stack: 'Java, JavaScript, HTML, CSS',
      github: 'https://github.com/MatheoJ/ReactIF_FrontEnd',
      demo: 'https://github.com/MatheoJ/AirWatcher',
      detail:i18next.t('Reactif_Text')
    },   
    {
      id: 4,
      image: IMG4,
      title: i18next.t('Predictif'),
      stack: 'Java',
      github: 'https://github.com/MatheoJ/Predict-IF',
      demo: 'https://github.com/MatheoJ/AirWatcher',
      detail: i18next.t('Predictif_Text')
    },
    {
      id: 5,
      image: IMG5,
      title: i18next.t('Pandemic'),
      stack: 'Java',
      github: 'https://github.com/MatheoJ/Pandemic',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail:i18next.t('Pandemic_Text')
    }, 
    {
      id: 6,
      image: IMG6,
      title: i18next.t('Tracker'),
      stack: 'Python, Matlab',
      github: '',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail:i18next.t('Tracker_Text')
    },
    {
      id: 7,
      image: IMG7,
      title: i18next.t('Minautorus'),
      stack: 'Javascript: framework Electron',
      github: 'https://github.com/MatheoJ/Minotaurus',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail:i18next.t('Minautorus_Text')
    }
    ,
    {
      id: 8,
      image: IMG8,
      title: i18next.t('Pipetor'),
      stack: 'Arduino, C',
      github: '',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail:i18next.t('Pipetor_Text')
    }
    ,
    
    {
      id: 10,
      image: IMG10,
      title: i18next.t('Labelisor'),
      stack: 'Python',
      github: '',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail:i18next.t('Labelisor_Text')
    }
    ,
    
    {
      id: 12,
      image: IMG12,
      title: i18next.t('TagDetector'),
      stack: 'Python: OpenCV',
      github: 'https://github.com/MatheoJ/Tag-Aruco-Detector',
      demo: 'https://github.com/MatheoJ/Pandemic',
      detail: i18next.t('TagDetector_Text')
    }
    
  ]

  return (
    <section id='portfolio'>
      
      <h2>
      {t('My Projects')}
      </h2>
      <div className= "container portfolio__container">
        {
          data.map(({id, image, title, stack,github, demo, detail})=>{
            
            if(github.length==0){
              return(

                <article key={id} className='portfolio__item'>
                  <div class="wrapper">
                    <div class='preview'>
                      <div className='portfolio__item-image'>
                        <img src={image} alt= {title} />
                      </div>
                      <h3>{title}</h3> 
                                         
                      <div class='detail'>
                      <div className='portfolio__item-image'>
                        <img src={image} alt= {title} />
                      </div>
                      <h4>
                        {detail} 
                      </h4>                   
                    </div> 
                    </div>
                                     
                  </div>
                  <h5>{stack}</h5>
                </article>  
              )
            }
            else{
              return(

                <article key={id} className='portfolio__item'>
                  <div class="wrapper">
                    <div class='preview'>
                      <div className='portfolio__item-image'>
                        <img src={image} alt= {title} />
                      </div>
                      <h3>{title}</h3> 
                                        
                      <div class='detail'>
                      <div className='portfolio__item-image'>
                        <img src={image} alt= {title} />
                      </div>
                      <h4>
                        {detail}
                      </h4>                   
                    </div> 
                    </div>
                                    
                  </div>
                  <h5>{stack}</h5> 
                  <div className='portfolio__item-cta'>
                        <a href={github} className='btn' target='_blank'>Github</a>
                  </div>
                </article>  
              ) 
            }            
          })
        }
        

        
       
      </div>
    </section>
  )
}

export default Portfolio