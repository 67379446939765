import React from 'react'
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import cookies from 'js-cookie'
import 'flag-icon-css/css/flag-icons.css'

const CTA_Lang = () => {
  const currentLanguage = cookies.get('i18next')||'en'
  if(currentLanguage=='en'){
    return (
      <div className='CTA__Lang'>
          <button className='btn btn-primary' name="button" onClick={()=> i18next.changeLanguage("fr")}>
            Français 
            <span
            className={'flag-icon flag-icon-fr mx-4'}
            ></span>
          </button>
      </div> 
    )
  }
  else{
    return (
      <div className='CTA__Lang'>
          <button className='btn btn-primary' name="button" onClick={()=> i18next.changeLanguage("en")}>
            English 
            <span>

            </span>
            <span
            className={'flag-icon flag-icon-gb mx-4'}
            ></span>
          </button>
      </div> 
    )
  }  
}

export default CTA_Lang