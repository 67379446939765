import React from 'react'
import './Experience.css'
import {SiCplusplus} from 'react-icons/si'
import {FaJava} from 'react-icons/fa'
import {FaPython} from 'react-icons/fa'
import {SiJavascript} from 'react-icons/si'
import {AiOutlineConsoleSql} from 'react-icons/ai'
import {BiGitBranch} from 'react-icons/bi'
import {SiVisualstudiocode} from 'react-icons/si'
import {SiLinux} from 'react-icons/si'
import {FaDocker} from 'react-icons/fa'
import {SiApachenetbeanside} from 'react-icons/si'
import {SiCsharp} from 'react-icons/si'
import {FaUnity} from 'react-icons/fa'
import {SiUnrealengine} from 'react-icons/si'
import {SiOpengl} from 'react-icons/si'
import {SiCloudera} from 'react-icons/si'
import {ReactComponent as QtLogo}  from '../../assets/Qt_logo_2016.svg';
import {ReactComponent as SDLLogo}  from '../../assets/SDL_logo.svg';

import { useTranslation } from "react-i18next";

const Experience = () => {
  const{t}= useTranslation()
  return (
    <section id='experience'>
      <h2>{t('My Skills')} </h2>
      <div className='container experience__container '>
        <div className='experience__frontend'>
          <h3>
          {t('Language')}
          </h3>
          <div className='experience__content'>
            <article className='experience__details'>
             <SiCplusplus className='experience__details-icon'/>
             <div>
              <h4>C++</h4>
              <small className='text-light '>{t('Experimented')}</small>
            </div>
            </article>
            <article className='experience__details'>
             <FaJava className='experience__details-icon'/>
            <div>
              <h4>Java</h4>
              <small className='text-light '>{t('Experimented')}</small>
             </div>
            </article>
            <article className='experience__details'>
             <SiCsharp className='experience__details-icon'/>
            <div>
              <h4>C#</h4>
              <small className='text-light '>{t('Intermediate')}</small>
             </div>
            </article>
            <article className='experience__details'>
             <FaPython className='experience__details-icon'/>
             <div>
              <h4>Python</h4>
              <small className='text-light '>{t('Intermediate')}</small>
             </div>
            </article>
            <article className='experience__details'>
             <SiJavascript className='experience__details-icon'/>
             <div>
              <h4>Javascript</h4>
              <small className='text-light '>{t('Intermediate')}</small>
             </div>
            </article>
            <article className='experience__details'>
            <SiCloudera className='experience__details-icon'/>
             <div>
              <h4>C</h4>
              <small className='text-light '>{t('Intermediate')}</small>
             </div>
            </article>
          </div>
          
        </div>
        <div className='experience__backend'>
          <h3>
            {t('Tools')}
          </h3>
          <div className='experience__content'>
              <article className='experience__details'>
              <FaUnity className='experience__details-icon'/>
              <div>
              <h4>Unity</h4>
              <small className='text-light '>{t('Intermediate')}</small>
              </div>
              </article>
              <article className='experience__details'>
              <SiUnrealengine className='experience__details-icon'/>
              <div>
              <h4>Unreal Engine</h4>
              <small className='text-light '>{t('Intermediate')}</small>
              </div>
              </article>
              <article className='experience__details'>
              <SiOpengl className='experience__details-icon'/>
              <div>
              <h4>OpenGL</h4>
              <small className='text-light '>{t('Intermediate')}</small>
              </div>
              </article>
              <article className='experience__details'>
              <QtLogo className='experience__details-icon'/>
              <div>
              <h4>Qt Framework</h4>
              <small className='text-light '>{t('Experimented')}</small>
              </div>
              </article>
              <article className='experience__details'>
              <SiLinux className='experience__details-icon'/>
              <div>
                <h4>Linux</h4>
                <small className='text-light '>{t('Intermediate')}</small>
              </div>
              </article>
              <article className='experience__details'>
              <SDLLogo className='experience__details-icon'/>
              <div>
                <h4>SDL</h4>
                <small className='text-light '>{t('Intermediate')}</small>
              </div>
              </article>
              
            </div>
        </div>
      </div>
    </section>
  )
}

export default Experience